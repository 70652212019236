// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../.cache/caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---content-en-contribute-bounties-index-mdx": () => import("./../../content/en/contribute/bounties/index.mdx" /* webpackChunkName: "component---content-en-contribute-bounties-index-mdx" */),
  "component---content-en-contribute-bounties-working-on-a-bounty-mdx": () => import("./../../content/en/contribute/bounties/working-on-a-bounty.mdx" /* webpackChunkName: "component---content-en-contribute-bounties-working-on-a-bounty-mdx" */),
  "component---content-en-contribute-content-contributor-tools-mdx": () => import("./../../content/en/contribute/content/contributor-tools.mdx" /* webpackChunkName: "component---content-en-contribute-content-contributor-tools-mdx" */),
  "component---content-en-contribute-content-index-mdx": () => import("./../../content/en/contribute/content/index.mdx" /* webpackChunkName: "component---content-en-contribute-content-index-mdx" */),
  "component---content-en-contribute-content-reviewer-guide-mdx": () => import("./../../content/en/contribute/content/reviewer-guide.mdx" /* webpackChunkName: "component---content-en-contribute-content-reviewer-guide-mdx" */),
  "component---content-en-contribute-content-using-github-mdx": () => import("./../../content/en/contribute/content/using-github.mdx" /* webpackChunkName: "component---content-en-contribute-content-using-github-mdx" */),
  "component---content-en-contribute-content-using-github-one-off-mdx": () => import("./../../content/en/contribute/content/using-github-one-off.mdx" /* webpackChunkName: "component---content-en-contribute-content-using-github-one-off-mdx" */),
  "component---content-en-contribute-content-using-github-ongoing-mdx": () => import("./../../content/en/contribute/content/using-github-ongoing.mdx" /* webpackChunkName: "component---content-en-contribute-content-using-github-ongoing-mdx" */),
  "component---content-en-contribute-content-writing-style-guide-mdx": () => import("./../../content/en/contribute/content/writing-style-guide.mdx" /* webpackChunkName: "component---content-en-contribute-content-writing-style-guide-mdx" */),
  "component---content-en-contribute-governance-and-risk-meetings-index-mdx": () => import("./../../content/en/contribute/governance_and_risk_meetings/index.mdx" /* webpackChunkName: "component---content-en-contribute-governance-and-risk-meetings-index-mdx" */),
  "component---content-en-contribute-governance-and-risk-meetings-summaries-mdx": () => import("./../../content/en/contribute/governance_and_risk_meetings/summaries.mdx" /* webpackChunkName: "component---content-en-contribute-governance-and-risk-meetings-summaries-mdx" */),
  "component---content-en-contribute-governance-and-risk-meetings-take-notes-call-template-governance-and-risk-meetings-mdx": () => import("./../../content/en/contribute/governance_and_risk_meetings/take-notes/call-template-governance-and-risk-meetings.mdx" /* webpackChunkName: "component---content-en-contribute-governance-and-risk-meetings-take-notes-call-template-governance-and-risk-meetings-mdx" */),
  "component---content-en-contribute-governance-and-risk-meetings-take-notes-formatting-guide-mdx": () => import("./../../content/en/contribute/governance_and_risk_meetings/take-notes/formatting-guide.mdx" /* webpackChunkName: "component---content-en-contribute-governance-and-risk-meetings-take-notes-formatting-guide-mdx" */),
  "component---content-en-contribute-governance-and-risk-meetings-take-notes-mdx": () => import("./../../content/en/contribute/governance_and_risk_meetings/take-notes.mdx" /* webpackChunkName: "component---content-en-contribute-governance-and-risk-meetings-take-notes-mdx" */),
  "component---content-en-contribute-governance-and-risk-meetings-take-notes-note-taking-team-mdx": () => import("./../../content/en/contribute/governance_and_risk_meetings/take-notes/note-taking-team.mdx" /* webpackChunkName: "component---content-en-contribute-governance-and-risk-meetings-take-notes-note-taking-team-mdx" */),
  "component---content-en-contribute-hackathons-guide-to-hacking-mdx": () => import("./../../content/en/contribute/hackathons/guide-to-hacking.mdx" /* webpackChunkName: "component---content-en-contribute-hackathons-guide-to-hacking-mdx" */),
  "component---content-en-contribute-hackathons-index-mdx": () => import("./../../content/en/contribute/hackathons/index.mdx" /* webpackChunkName: "component---content-en-contribute-hackathons-index-mdx" */),
  "component---content-en-contribute-index-mdx": () => import("./../../content/en/contribute/index.mdx" /* webpackChunkName: "component---content-en-contribute-index-mdx" */),
  "component---content-en-contribute-meetups-budget-guide-mdx": () => import("./../../content/en/contribute/meetups/budget-guide.mdx" /* webpackChunkName: "component---content-en-contribute-meetups-budget-guide-mdx" */),
  "component---content-en-contribute-meetups-event-formats-guide-mdx": () => import("./../../content/en/contribute/meetups/event-formats-guide.mdx" /* webpackChunkName: "component---content-en-contribute-meetups-event-formats-guide-mdx" */),
  "component---content-en-contribute-meetups-getting-started-guide-mdx": () => import("./../../content/en/contribute/meetups/getting-started-guide.mdx" /* webpackChunkName: "component---content-en-contribute-meetups-getting-started-guide-mdx" */),
  "component---content-en-contribute-meetups-index-mdx": () => import("./../../content/en/contribute/meetups/index.mdx" /* webpackChunkName: "component---content-en-contribute-meetups-index-mdx" */),
  "component---content-en-contribute-meetups-promotion-guide-mdx": () => import("./../../content/en/contribute/meetups/promotion-guide.mdx" /* webpackChunkName: "component---content-en-contribute-meetups-promotion-guide-mdx" */),
  "component---content-en-contribute-translations-editors-mdx": () => import("./../../content/en/contribute/translations/editors.mdx" /* webpackChunkName: "component---content-en-contribute-translations-editors-mdx" */),
  "component---content-en-contribute-translations-index-mdx": () => import("./../../content/en/contribute/translations/index.mdx" /* webpackChunkName: "component---content-en-contribute-translations-index-mdx" */),
  "component---content-en-contribute-translations-translators-mdx": () => import("./../../content/en/contribute/translations/translators.mdx" /* webpackChunkName: "component---content-en-contribute-translations-translators-mdx" */),
  "component---content-en-funding-community-projects-community-projects-process-mdx": () => import("./../../content/en/funding/community-projects/community-projects-process.mdx" /* webpackChunkName: "component---content-en-funding-community-projects-community-projects-process-mdx" */),
  "component---content-en-funding-community-projects-index-mdx": () => import("./../../content/en/funding/community-projects/index.mdx" /* webpackChunkName: "component---content-en-funding-community-projects-index-mdx" */),
  "component---content-en-funding-development-grants-development-grants-info-mdx": () => import("./../../content/en/funding/development-grants/development-grants-info.mdx" /* webpackChunkName: "component---content-en-funding-development-grants-development-grants-info-mdx" */),
  "component---content-en-funding-development-grants-grantee-playbook-mdx": () => import("./../../content/en/funding/development-grants/grantee-playbook.mdx" /* webpackChunkName: "component---content-en-funding-development-grants-grantee-playbook-mdx" */),
  "component---content-en-funding-development-grants-index-mdx": () => import("./../../content/en/funding/development-grants/index.mdx" /* webpackChunkName: "component---content-en-funding-development-grants-index-mdx" */),
  "component---content-en-funding-hackathon-funding-mdx": () => import("./../../content/en/funding/hackathon-funding.mdx" /* webpackChunkName: "component---content-en-funding-hackathon-funding-mdx" */),
  "component---content-en-funding-index-mdx": () => import("./../../content/en/funding/index.mdx" /* webpackChunkName: "component---content-en-funding-index-mdx" */),
  "component---content-en-funding-meetup-funding-mdx": () => import("./../../content/en/funding/meetup-funding.mdx" /* webpackChunkName: "component---content-en-funding-meetup-funding-mdx" */),
  "component---content-en-index-mdx": () => import("./../../content/en/index.mdx" /* webpackChunkName: "component---content-en-index-mdx" */),
  "component---content-en-learn-blockchain-fundamentals-daos-mdx": () => import("./../../content/en/learn/blockchain-fundamentals/daos.mdx" /* webpackChunkName: "component---content-en-learn-blockchain-fundamentals-daos-mdx" */),
  "component---content-en-learn-blockchain-fundamentals-ethereum-mdx": () => import("./../../content/en/learn/blockchain-fundamentals/ethereum.mdx" /* webpackChunkName: "component---content-en-learn-blockchain-fundamentals-ethereum-mdx" */),
  "component---content-en-learn-blockchain-fundamentals-index-mdx": () => import("./../../content/en/learn/blockchain-fundamentals/index.mdx" /* webpackChunkName: "component---content-en-learn-blockchain-fundamentals-index-mdx" */),
  "component---content-en-learn-blockchain-fundamentals-stablecoins-mdx": () => import("./../../content/en/learn/blockchain-fundamentals/stablecoins.mdx" /* webpackChunkName: "component---content-en-learn-blockchain-fundamentals-stablecoins-mdx" */),
  "component---content-en-learn-blockchain-fundamentals-wallets-mdx": () => import("./../../content/en/learn/blockchain-fundamentals/wallets.mdx" /* webpackChunkName: "component---content-en-learn-blockchain-fundamentals-wallets-mdx" */),
  "component---content-en-learn-collateral-and-risk-dai-credit-system-mdx": () => import("./../../content/en/learn/collateral-and-risk/dai-credit-system.mdx" /* webpackChunkName: "component---content-en-learn-collateral-and-risk-dai-credit-system-mdx" */),
  "component---content-en-learn-collateral-and-risk-index-mdx": () => import("./../../content/en/learn/collateral-and-risk/index.mdx" /* webpackChunkName: "component---content-en-learn-collateral-and-risk-index-mdx" */),
  "component---content-en-learn-collateral-and-risk-risk-teams-mdx": () => import("./../../content/en/learn/collateral-and-risk/risk-teams.mdx" /* webpackChunkName: "component---content-en-learn-collateral-and-risk-risk-teams-mdx" */),
  "component---content-en-learn-collateral-and-risk-types-of-risk-mdx": () => import("./../../content/en/learn/collateral-and-risk/types-of-risk.mdx" /* webpackChunkName: "component---content-en-learn-collateral-and-risk-types-of-risk-mdx" */),
  "component---content-en-learn-dai-dsr-mdx": () => import("./../../content/en/learn/Dai/dsr.mdx" /* webpackChunkName: "component---content-en-learn-dai-dsr-mdx" */),
  "component---content-en-learn-dai-get-dai-borrow-dai-mdx": () => import("./../../content/en/learn/Dai/get-dai/borrow-dai.mdx" /* webpackChunkName: "component---content-en-learn-dai-get-dai-borrow-dai-mdx" */),
  "component---content-en-learn-dai-get-dai-buy-dai-mdx": () => import("./../../content/en/learn/Dai/get-dai/buy-dai.mdx" /* webpackChunkName: "component---content-en-learn-dai-get-dai-buy-dai-mdx" */),
  "component---content-en-learn-dai-get-dai-earn-dai-mdx": () => import("./../../content/en/learn/Dai/get-dai/earn-dai.mdx" /* webpackChunkName: "component---content-en-learn-dai-get-dai-earn-dai-mdx" */),
  "component---content-en-learn-dai-get-dai-mdx": () => import("./../../content/en/learn/Dai/get-dai.mdx" /* webpackChunkName: "component---content-en-learn-dai-get-dai-mdx" */),
  "component---content-en-learn-dai-index-mdx": () => import("./../../content/en/learn/Dai/index.mdx" /* webpackChunkName: "component---content-en-learn-dai-index-mdx" */),
  "component---content-en-learn-dai-use-dai-mdx": () => import("./../../content/en/learn/Dai/use-dai.mdx" /* webpackChunkName: "component---content-en-learn-dai-use-dai-mdx" */),
  "component---content-en-learn-governance-audit-exec-spells-mdx": () => import("./../../content/en/learn/governance/audit-exec-spells.mdx" /* webpackChunkName: "component---content-en-learn-governance-audit-exec-spells-mdx" */),
  "component---content-en-learn-governance-common-topics-mdx": () => import("./../../content/en/learn/governance/common-topics.mdx" /* webpackChunkName: "component---content-en-learn-governance-common-topics-mdx" */),
  "component---content-en-learn-governance-emergency-shutdown-mdx": () => import("./../../content/en/learn/governance/emergency-shutdown.mdx" /* webpackChunkName: "component---content-en-learn-governance-emergency-shutdown-mdx" */),
  "component---content-en-learn-governance-gov-risk-framework-2-mdx": () => import("./../../content/en/learn/governance/gov-risk-framework2.mdx" /* webpackChunkName: "component---content-en-learn-governance-gov-risk-framework-2-mdx" */),
  "component---content-en-learn-governance-gov-risk-framework-3-mdx": () => import("./../../content/en/learn/governance/gov-risk-framework3.mdx" /* webpackChunkName: "component---content-en-learn-governance-gov-risk-framework-3-mdx" */),
  "component---content-en-learn-governance-gov-risk-framework-mdx": () => import("./../../content/en/learn/governance/gov-risk-framework.mdx" /* webpackChunkName: "component---content-en-learn-governance-gov-risk-framework-mdx" */),
  "component---content-en-learn-governance-governance-faq-md": () => import("./../../content/en/learn/governance/governance-faq.md" /* webpackChunkName: "component---content-en-learn-governance-governance-faq-md" */),
  "component---content-en-learn-governance-how-voting-works-mdx": () => import("./../../content/en/learn/governance/how-voting-works.mdx" /* webpackChunkName: "component---content-en-learn-governance-how-voting-works-mdx" */),
  "component---content-en-learn-governance-index-mdx": () => import("./../../content/en/learn/governance/index.mdx" /* webpackChunkName: "component---content-en-learn-governance-index-mdx" */),
  "component---content-en-learn-governance-mkr-token-mdx": () => import("./../../content/en/learn/governance/mkr-token.mdx" /* webpackChunkName: "component---content-en-learn-governance-mkr-token-mdx" */),
  "component---content-en-learn-governance-off-chain-gov-mdx": () => import("./../../content/en/learn/governance/off-chain-gov.mdx" /* webpackChunkName: "component---content-en-learn-governance-off-chain-gov-mdx" */),
  "component---content-en-learn-governance-on-chain-gov-mdx": () => import("./../../content/en/learn/governance/on-chain-gov.mdx" /* webpackChunkName: "component---content-en-learn-governance-on-chain-gov-mdx" */),
  "component---content-en-learn-governance-participate-mdx": () => import("./../../content/en/learn/governance/participate.mdx" /* webpackChunkName: "component---content-en-learn-governance-participate-mdx" */),
  "component---content-en-learn-governance-voting-setup-mdx": () => import("./../../content/en/learn/governance/voting-setup.mdx" /* webpackChunkName: "component---content-en-learn-governance-voting-setup-mdx" */),
  "component---content-en-learn-index-mdx": () => import("./../../content/en/learn/index.mdx" /* webpackChunkName: "component---content-en-learn-index-mdx" */),
  "component---content-en-learn-maker-dao-core-principles-mdx": () => import("./../../content/en/learn/MakerDAO/core-principles.mdx" /* webpackChunkName: "component---content-en-learn-maker-dao-core-principles-mdx" */),
  "component---content-en-learn-maker-dao-dai-foundation-mdx": () => import("./../../content/en/learn/MakerDAO/dai-foundation.mdx" /* webpackChunkName: "component---content-en-learn-maker-dao-dai-foundation-mdx" */),
  "component---content-en-learn-maker-dao-index-mdx": () => import("./../../content/en/learn/MakerDAO/index.mdx" /* webpackChunkName: "component---content-en-learn-maker-dao-index-mdx" */),
  "component---content-en-learn-maker-dao-maker-foundation-mdx": () => import("./../../content/en/learn/MakerDAO/maker-foundation.mdx" /* webpackChunkName: "component---content-en-learn-maker-dao-maker-foundation-mdx" */),
  "component---content-en-learn-oracles-how-it-works-mdx": () => import("./../../content/en/learn/Oracles/how-it-works.mdx" /* webpackChunkName: "component---content-en-learn-oracles-how-it-works-mdx" */),
  "component---content-en-learn-oracles-index-mdx": () => import("./../../content/en/learn/Oracles/index.mdx" /* webpackChunkName: "component---content-en-learn-oracles-index-mdx" */),
  "component---content-en-learn-oracles-security-mdx": () => import("./../../content/en/learn/Oracles/security.mdx" /* webpackChunkName: "component---content-en-learn-oracles-security-mdx" */),
  "component---content-en-learn-vaults-index-mdx": () => import("./../../content/en/learn/vaults/index.mdx" /* webpackChunkName: "component---content-en-learn-vaults-index-mdx" */),
  "component---content-en-learn-vaults-liquidation-mdx": () => import("./../../content/en/learn/vaults/liquidation.mdx" /* webpackChunkName: "component---content-en-learn-vaults-liquidation-mdx" */),
  "component---content-en-learn-vaults-risk-mdx": () => import("./../../content/en/learn/vaults/risk.mdx" /* webpackChunkName: "component---content-en-learn-vaults-risk-mdx" */),
  "component---content-en-learn-vaults-stability-fees-mdx": () => import("./../../content/en/learn/vaults/stability-fees.mdx" /* webpackChunkName: "component---content-en-learn-vaults-stability-fees-mdx" */),
  "component---content-en-learn-vaults-vault-onboarding-guide-mdx": () => import("./../../content/en/learn/vaults/vault-onboarding-guide.mdx" /* webpackChunkName: "component---content-en-learn-vaults-vault-onboarding-guide-mdx" */),
  "component---content-en-learn-vaults-vaults-tutorial-mdx": () => import("./../../content/en/learn/vaults/vaults-tutorial.mdx" /* webpackChunkName: "component---content-en-learn-vaults-vaults-tutorial-mdx" */),
  "component---content-en-resources-content-index-mdx": () => import("./../../content/en/resources/content/index.mdx" /* webpackChunkName: "component---content-en-resources-content-index-mdx" */),
  "component---content-en-resources-engineering-index-mdx": () => import("./../../content/en/resources/engineering/index.mdx" /* webpackChunkName: "component---content-en-resources-engineering-index-mdx" */),
  "component---content-en-resources-engineering-language-mdx": () => import("./../../content/en/resources/engineering/language.mdx" /* webpackChunkName: "component---content-en-resources-engineering-language-mdx" */),
  "component---content-en-resources-engineering-navigation-mdx": () => import("./../../content/en/resources/engineering/navigation.mdx" /* webpackChunkName: "component---content-en-resources-engineering-navigation-mdx" */),
  "component---content-en-resources-engineering-search-mdx": () => import("./../../content/en/resources/engineering/search.mdx" /* webpackChunkName: "component---content-en-resources-engineering-search-mdx" */),
  "component---content-en-resources-engineering-translations-mdx": () => import("./../../content/en/resources/engineering/translations.mdx" /* webpackChunkName: "component---content-en-resources-engineering-translations-mdx" */),
  "component---content-en-resources-index-mdx": () => import("./../../content/en/resources/index.mdx" /* webpackChunkName: "component---content-en-resources-index-mdx" */),
  "component---content-en-resources-onboarding-index-mdx": () => import("./../../content/en/resources/onboarding/index.mdx" /* webpackChunkName: "component---content-en-resources-onboarding-index-mdx" */),
  "component---content-en-resources-technical-content-cheatsheet-mdx": () => import("./../../content/en/resources/technical-content/cheatsheet.mdx" /* webpackChunkName: "component---content-en-resources-technical-content-cheatsheet-mdx" */),
  "component---content-en-resources-technical-content-content-design-guide-mdx": () => import("./../../content/en/resources/technical-content/content-design-guide.mdx" /* webpackChunkName: "component---content-en-resources-technical-content-content-design-guide-mdx" */),
  "component---content-en-resources-technical-content-frontmatter-mdx": () => import("./../../content/en/resources/technical-content/frontmatter.mdx" /* webpackChunkName: "component---content-en-resources-technical-content-frontmatter-mdx" */),
  "component---content-en-resources-technical-content-icons-recipes-mdx": () => import("./../../content/en/resources/technical-content/icons-recipes.mdx" /* webpackChunkName: "component---content-en-resources-technical-content-icons-recipes-mdx" */),
  "component---content-en-resources-technical-content-index-mdx": () => import("./../../content/en/resources/technical-content/index.mdx" /* webpackChunkName: "component---content-en-resources-technical-content-index-mdx" */),
  "component---content-en-resources-technical-content-installation-guide-mdx": () => import("./../../content/en/resources/technical-content/installation-guide.mdx" /* webpackChunkName: "component---content-en-resources-technical-content-installation-guide-mdx" */),
  "component---content-en-resources-technical-content-palette-recipes-mdx": () => import("./../../content/en/resources/technical-content/palette-recipes.mdx" /* webpackChunkName: "component---content-en-resources-technical-content-palette-recipes-mdx" */),
  "component---content-en-resources-technical-content-styleguide-mdx": () => import("./../../content/en/resources/technical-content/styleguide.mdx" /* webpackChunkName: "component---content-en-resources-technical-content-styleguide-mdx" */),
  "component---content-en-social-mdx": () => import("./../../content/en/social.mdx" /* webpackChunkName: "component---content-en-social-mdx" */),
  "component---content-en-test-mdx": () => import("./../../content/en/test.mdx" /* webpackChunkName: "component---content-en-test-mdx" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

